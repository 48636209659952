import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText, Divider } from "@mui/material";
// cmp
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"0101">;
	showFull: boolean;
}>;

const D0101 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const isReadAndWrite = cluster?.[ClusterConstants.D0101.Attributes.ActuatorPresent] ?? cluster?.[ClusterConstants.D0101.Attributes.ReadWriteStatus] ?? true;
	const isActuatorPresent = cluster?.[ClusterConstants.D0101.Attributes.ActuatorPresent] ?? true;
	const doorState = cluster?.[ClusterConstants.D0101.Attributes.DoorState] ?? "FF";

	const [toggle, setToggle] = useDynamicUpdateState(Boolean(props.deviceType.formatValue(cluster)));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleToggle = (isInputChecked: boolean) => {
		setToggle(isInputChecked);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: isInputChecked ? ClusterConstants.D0101.CmdIds.SecureLock : ClusterConstants.D0101.CmdIds.UnlockLock,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText primary={t(toggle ? "clusters.D0101.lockState.locked" : "clusters.D0101.lockState.unlocked")} />
					<ListItemContainer>
						<CustomSwitch
							checked={toggle}
							onChange={handleToggle}
							disabled={!isReadAndWrite}
						/>
					</ListItemContainer>
				</ListItem>
				{isActuatorPresent &&
					<>
						<Divider />
						<ListItem>
							<ListItemText>{t("clusters.D0101.doorStateTitle")}</ListItemText>
							<ListItemText>{t(`clusters.D0101.doorState.${doorState}`)}</ListItemText>
						</ListItem>
					</>
				}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return (
		<>
			<CustomSwitch
				className="switch-0101"
				checked={toggle}
				onChange={handleToggle}
				disabled={!isReadAndWrite}
			/>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

D0101.defaultProps = {
	showFull: false,
};

export default D0101;
