import { memo } from "react";
import { Switch } from "@mui/material";
// types
import type { SetRequired } from "type-fest";
import type { SwitchProps } from "@mui/material";

type OmitSwitchProps = "onChange";
type Props = Readonly<SetRequired<Omit<SwitchProps, OmitSwitchProps>, "checked"> & {
	onChange: (checked: boolean) => void;
	vertical?: boolean;
}>;

const CustomSwitch = ({ onChange, vertical = false, ...props }: Props) => (
	<Switch
		{...props}
		onChange={(event) => (onChange(event.target.checked))}
		sx={vertical ? { transform: "rotate(-90deg)", marginRight: "-8px" } : undefined}
	/>
);

export default memo(CustomSwitch);
