import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText, Button } from "@mui/material";
// cmp
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import DeviceType from "../../services/device-type";
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"0502">;
	showFull: boolean;
}>;

const D0502 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster0500 = props.epDevice.getClusterByCapAndClusterId(DeviceType.D0500.cap, DeviceType.D0500.clusterId);
	const value = (cluster0500 && props.epDevice.getRocIdData("icon_blink"))
		? Boolean(DeviceType.D0500.formatValue(cluster0500))
		: false;

	const [active, setActive] = useDynamicUpdateState(value);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleToggle = (isInputChecked: boolean) => {
		setActive(isInputChecked);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0502.CmdIds.Arm,
			value: isInputChecked ? ClusterConstants.D0502.CmdPayloads.Activated : ClusterConstants.D0502.CmdPayloads.Deactivated,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText primary={t(active ? "clusters.D0502.activated" : "clusters.D0502.deactivated")} />
					<ListItemContainer>
						<CustomSwitch
							className="switch-0502"
							checked={active}
							onChange={handleToggle}
						/>
					</ListItemContainer>
				</ListItem>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	if (active) {
		return (
			<>
				<Button onClick={() => (handleToggle(false))}>
					{t("clusters.D0502.buttonText")}
				</Button>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

D0502.defaultProps = {
	showFull: false,
};

export default D0502;
