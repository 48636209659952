import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"C38E">;
	showFull?: boolean;
}>;

const DC38E = (props: Props) => {
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	if (props.showFull) {
		return (
			<ListItem>
				<ListItemText primary={t("clusters.DC38E.title")} />
				<ListItemText primary={props.deviceType.formatValue(cluster)} />
			</ListItem>
		);
	}
	return null;
};

DC38E.defaultProps = {
	showFull: false,
};

export default DC38E;
