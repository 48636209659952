import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText, Button, Divider } from "@mui/material";
// cmp
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { hex2Decimal } from "../../services/utils";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"0100">;
	showFull: boolean;
}>;

const D0100 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const value = cluster?.[ClusterConstants.D0100.Attributes.TerminalOrientation];

	const [terminalOrientation, setTerminalOrientation] = useDynamicUpdateState(Boolean(value));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleAutoConfigClick = () => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.D0100.Attributes.Mode,
				datatype: Constants.DataType.UInt24Bit,
				value: ClusterConstants.D0100.CmdPayloads.Configure,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"0100">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleTerminalOrientationToggle = (isInputChecked: boolean) => {
		setTerminalOrientation(isInputChecked);

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.D0100.Attributes.TerminalOrientation,
				datatype: Constants.DataType.Boolean,
				value: isInputChecked,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"0100">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText primary={t("clusters.D0100.title")} secondary={t("clusters.D0100.subTitle")} />
					<ListItemContainer>
						{(props.deviceType.formatValue(cluster) === hex2Decimal(ClusterConstants.D0100.CmdPayloads.Configure))
							? t("clusters.D0100.running")
							:
							<Button sx={{ margin: "-10px" }} onClick={handleAutoConfigClick}>
								{t("clusters.D0100.start")}
							</Button>
						}
					</ListItemContainer>
				</ListItem>
				{(value !== undefined) &&
					<>
						<Divider />
						<ListItem>
							<ListItemText primary={t("clusters.D0100.directionTitle")} secondary={t("clusters.D0100.directionSubTitle")} />
							<ListItemContainer><CustomSwitch checked={terminalOrientation} onChange={handleTerminalOrientationToggle} /></ListItemContainer>
						</ListItem>
					</>
				}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

D0100.defaultProps = {
	showFull: false,
};

export default D0100;
